
import { Component, Watch } from 'vue-property-decorator';
import TranslationalObjectRepository from '@/repositories/TranslationalObjectRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import Locale from '@/models/Locale';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { translationsHaveChanges, updateTranslationData } from '@/helpers/TranslationHelper';
import { LocaleValues } from '@/interfaces/components/translations/LocaleValues';
import { mixins } from 'vue-class-component';
import TranslationTopBar from '@/components/views/TranslationScreen/TranslationTopBar.vue';
import RichTextEditor from '@/components/views/CmsIndex/RichTextEditor.vue';

@Component({
    name: 'TranslationScreen',
    components: { TranslationTopBar, RichTextEditor },
})
// @ts-ignore
export default class TranslationScreen extends mixins<CheckUnsavedDataMixin>(CheckUnsavedDataMixin) {
    private entityName = '';
    private attribute = '';
    private locale = 'hr';
    private baseLocale = 'sl';
    private translatedStrings: LocaleValues = {};
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private hasUnsavedData = false;
    private showOnlyEmpty = false;
    private shouldShowRichTextMenu = false;
    private richTextEditorIndex = 0;

    private searchOptions = {
        page: 1,
        size: 50,
        query: '',
        total: 0,
    };

    private get translatableObject() {
        return TranslationalObjectRepository.getFirst();
    }

    private get iterableTranslatableProperties() {
        if (this.translatableObject == null) {
            return [];
        }

        return this.translatableObject.getTranslatableProperties.filter(
            (translationObject: any) => !!translationObject.baseLocaleString
        );
    }

    private setToFirstPageAndUpdateTranslationData() {
        this.searchOptions.page = 1;
        this.updateTranslationData();
    }

    private async updateTranslationData() {
        this.loadingOverlay.start();
        try {
            this.translatedStrings = await updateTranslationData({
                entityName: this.entityName,
                attribute: this.attribute,
                locale: this.locale,
                baseLocale: this.baseLocale,
                page: this.searchOptions.page,
                pageSize: this.searchOptions.size,
                query: this.searchOptions.query,
                showOnlyEmpty: this.showOnlyEmpty,
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private updateRichTextContent(text: string, translatableProperty: string) {
        this.translatedStrings[translatableProperty] = text;
        this.hasUnsavedData = true;
    }

    private async onPaginationChange() {
        const areAnyTranslationsUpdated = translationsHaveChanges(this.translatedStrings);
        const oldPage = this.searchOptions.page;

        if (areAnyTranslationsUpdated && !this.confirmLeave()) {
            await this.$nextTick();
            // updates the search options page to the old page number so pagination doesn't show the wrong page
            this.searchOptions.page = oldPage;
            return;
        }

        await this.$nextTick();
        this.updateTranslationData();
    }

    private onUpdatePagination({ totalNumber }: { totalNumber: number }) {
        if (totalNumber == null) {
            return;
        }

        this.searchOptions.total = totalNumber;
    }

    private triggerRichTextMenu(event: any, index: number) {
        this.shouldShowRichTextMenu = true;
        this.richTextEditorIndex = index;
    }

    private hideRichTextMenu(event: any) {
        const editor = event.target.closest('.editor');

        if (editor) {
            return;
        }

        this.shouldShowRichTextMenu = false;
    }

    private async created() {
        EventBus.$on(EventBusEvents.emitTranslationPagination, this.onUpdatePagination);
        try {
            await Locale.getAll();
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            this.loadingOverlay.stop();
            return;
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.emitTranslationPagination, this.onUpdatePagination);
    }

    @Watch('$route', { immediate: true, deep: true })
    private async onRouteChange() {
        this.entityName = this.$route.params.entityName;
        this.attribute = this.$route.params.attribute;
        await this.updateTranslationData();
    }
}
